$(document).on('turbolinks:load', function() {
  $('#company_subscription_subscription_id').on('change', function() {
    const subscription = $('#company_subscription_subscription_id :selected').data('subscription');

    document.getElementById('company_subscription_slots').value = subscription.slots;
    document.getElementById('company_subscription_max_courses').value = subscription.max_courses;
    document.getElementById('company_subscription_duration').value = subscription.duration;
    document.getElementById('company_subscription_gamification').checked = subscription.gamification;
    document.getElementById('company_subscription_individual_session').value = subscription.individual_session;
    document.getElementById('company_subscription_group_session').value = subscription.group_session;
    document.getElementById('company_subscription_max_people_on_group_session').value = subscription.max_people_on_group_session;
    document.getElementById('company_subscription_certificated_session').value = subscription.certificated_session;
    document.getElementById('company_subscription_max_people_on_certificated_session').value = subscription.max_people_on_certificated_session;
    document.getElementById('company_subscription_duration').dispatchEvent(new Event('change'));
  })
});
