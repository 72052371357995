// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("bootstrap")

require("trix")
require("@rails/actiontext")
require("custom/fontawesome")
require("custom/solid")
require("custom/users")
require("jquery")
require("company_subscriptions/subscription_select")
require("company_subscriptions/end_date")
require("company_subscription_courses/company_subscriptions_select")
require("company_subscription_courses/end_date")

import 'core-js/stable'
import 'regenerator-runtime/runtime'

$( document ).on('turbolinks:load', function() {
  $('#sidebarCollapse').on('click', function () {
     $('#sidebar').toggleClass('active');
    });
});

$(document).ready(function() {
  $(".resource_select").on("change", function() {
    $.ajax({
      url: "/collaterals/resources",
      type: "GET",
      dataType: "script",
      data: {
        resource_type: $('.resource_select option:selected').val()
      }
    });
  });
});

$(document).ready(function() {
  $('#owner_select_all').change(function() {
    if (this.checked) {
      $('.multiple_select_owners option').prop('selected', true);
    } else {
      $('.multiple_select_owners option').prop('selected', false);
    }
  })
});
