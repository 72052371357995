export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + parseInt(days));
  return result;
}

export function diffDays(end_date, start_date) {
  var result = parseInt((end_date - start_date) / (1000 * 60 * 60 * 24), 10);
  if (result < 0) result = 0;
  return result;
}
