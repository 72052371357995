import { addDays } from '../date/helpers.js'

$(document).on('turbolinks:load', function() {
  $('#company_subscription_course_start_date, #duration').on('change', function() {
    const duration = $('#duration').val();
    const start_date = new Date($('#company_subscription_course_start_date').val());
    let end_date_field = document.getElementById('company_subscription_course_end_date')
    var end_date = addDays(start_date, duration);
    if (duration) {
      end_date_field.value = end_date.toJSON().slice(0,10);
    }
  })
});
