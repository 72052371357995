$(document).on('turbolinks:load', function() {
  $('#company_subscription_course_company_subscription_id').on('change', function() {
    const company_subscription = $('#company_subscription_course_company_subscription_id :selected').data('company-subscription');
    const duration = document.getElementById('duration');

    document.getElementById('company_id').value = company_subscription.company_id;
    document.getElementById('company_subscription_course_slots').value = company_subscription.slots;
    document.getElementById('company_subscription_course_gamification').checked = company_subscription.gamification;
    document.getElementById('company_subscription_course_individual_session').value = company_subscription.individual_session;
    document.getElementById('company_subscription_course_group_session').value = company_subscription.group_session;
    document.getElementById('company_subscription_course_max_people_on_group_session').value = company_subscription.max_people_on_group_session;
    document.getElementById('company_subscription_course_certificated_session').value = company_subscription.certificated_session;
    document.getElementById('company_subscription_course_max_people_on_certificated_session').value = company_subscription.max_people_on_certificated_session;
    duration.value = company_subscription.duration;
    duration.dispatchEvent(new Event('change'));
  })
});

$(document).on('turbolinks:load', function(){
  const company_subscription = $('#company_subscription_course_company_subscription_id :selected').data('company-subscription');
  if (company_subscription) document.getElementById('company_id').value = company_subscription.company_id;
})
