$( document ).on('turbolinks:load', function() {
	$('#courses').on('change', function() {
		var course_id = $('#courses :selected').val();
		if (course_id) {
			$('#scheduled_courses').show();
			$.ajax({
				type: "GET",
				contentType: "application/json; charset=utf-8",
				url: "/scheduled_courses/dropdown_values.js?&course_id=" + parseInt(course_id),
				dataType: 'script',
				success: function(result) {},
				error: function() {
				  alert("something went wrong!");
				}
			});
		} else {
			$('#scheduled_courses').hide();
			$('#scheduled_courses').empty();
		}
	})
});
