import { addDays, diffDays } from '../date/helpers.js'

$(document).on('turbolinks:load', function() {
  $('#company_subscription_start_date, #company_subscription_duration').on('change', function() {
    const duration = $('#company_subscription_duration').val();
    const start_date = new Date($('#company_subscription_start_date').val());
    let end_date_field = document.getElementById('company_subscription_end_date')
    var end_date = addDays(start_date, duration);
    if (duration) {
      end_date_field.value = end_date.toJSON().slice(0,10);
    }
  })
});

$(document).on('turbolinks:load', function() {
  $('#company_subscription_end_date').on('change', function() {
    const start_date = new Date ($('#company_subscription_start_date').val());
    const end_date = new Date($('#company_subscription_end_date').val());
    var duration = diffDays(end_date, start_date);
    document.getElementById('company_subscription_duration').value = duration;
  })
});


$(document).on('turbolinks:load', function() {
  $('#start_date').on('change', function() {
    const duration = $('#duration').val();
    const start_date = new Date($('#start_date').val());
    var end_date = addDays(start_date, duration);
    document.getElementById('end_date').value = datetimeWithTimezone(end_date).toJSON().slice(0,19);
  })
});

$(document).on('turbolinks:load', function() {
  $('#end_date').on('change', function() {
    const start_date = new Date ($('#start_date').val());
    var end_date = new Date($('#end_date').val());
    if (end_date < start_date) {
      end_date = addDays(start_date, 1);
      document.getElementById('end_date').value = datetimeWithTimezone(end_date).toJSON().slice(0,19);
    }
  })
});
